// FilterButton.js
'use strict';
import { connect } from 'react-redux';

import toggleModal from '../action/toggleModal.js';
import setSelectedFeedQueryDraftOption from '../action/setSelectedFeedQueryDraftOption.js';

import getOperationData from '../selector/getOperationData.js';
import getModalData from '../selector/getModalData.js';
import getSelectedFeedQueryOptions from '../selector/getSelectedFeedQueryOptions.js';
import { getCandidateQueryOptionObject } from '../selector/getFeedQueryObject.js';

import FilterButton from '../component/FilterButton.jsx';

import { QueryType } from '../resource/feedConstants.js';

const mapStateToProps = (state, { remoteConfigKeyName }) => {
  return {
    deviceWidth: getOperationData(state, ['device'], 'width'),
    isFilterMenuOpened: ['opening', 'display'].includes(
      getModalData(state, 'FilterMenu', 'status')
    ),
    isSortMenuOpened: ['opening', 'display'].includes(
      getModalData(state, 'SortMenu', 'status')
    ),
    selectedOptions: getSelectedFeedQueryOptions(
      state,
      remoteConfigKeyName,
      QueryType.FILTER
    ),
    optionObject: getCandidateQueryOptionObject(
      state,
      remoteConfigKeyName,
      QueryType.FILTER
    ),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    toggleModal: ({ modalId, payload, isOpen, modalOption }) =>
      dispatch(toggleModal({ modalId, payload, isOpen, modalOption })),
    setSelectedFeedQueryDraftOption: payload =>
      dispatch(setSelectedFeedQueryDraftOption(payload)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FilterButton);
